<template>
  <div>
      <div class="d-flex flex-wrap mb-6">
        <v-btn dark color="teal" @click="openCreateTrainingForm" class="mt-3 mr-3">Добавить тренинг</v-btn>
        <v-btn dark color="teal" @click="openCreateGroupForm" class="mt-3">Добавить группу тренингов</v-btn>
      </div>
      <v-simple-table>
        <template>
          <thead class="blue-grey lighten-5">
            <tr class="text-caption grey--text ">
              <th class="text-left font-weight-regular">
                Группа
              </th>
              <th class="text-left font-weight-regular">
                Дата создания
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in groups" >
              <tr :key="'group_'+item.id">
                <td><strong>{{ item.name }}</strong></td>
                <td class="date__width">{{ new Date(item.created_at) | moment("DD.MM.YYYY HH:mm") }}</td>
                <td class="two-actions__width">
                  <div class="d-flex">
                    <v-tooltip top>
                      <template #[`activator`]="{ on, attrs }">
                        <v-btn @click="editGroupForm(item.id)" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      Редактировать
                    </v-tooltip>
                    <v-tooltip top>
                      <template #[`activator`]="{ on, attrs }">
                        <v-btn @click="confirmGroupRemove(item.id)" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                      Удалить
                    </v-tooltip>
                  </div>
                </td>
              </tr>
              <template v-for="training in trainings.filter(t => t.group.id == item.id)">
              <tr :key="'training_'+training.id">
                <td style="padding-left: 50px">{{ training.name }}</td>
                <td>{{ new Date(training.created_at) | moment("DD.MM.YYYY HH:mm") }}</td>
                <td class="two-actions__width">
                  <div class="d-flex">
                    <v-tooltip top>
                      <template #[`activator`]="{ on, attrs }">
                        <v-btn @click="editTrainingForm(training.id)" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      Редактировать
                    </v-tooltip>
                    <v-tooltip top>
                      <template #[`activator`]="{ on, attrs }">
                        <v-btn @click="confirmTrainingRemove(training.id)" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                      Удалить
                    </v-tooltip>
                  </div>
                </td>
              </tr>
              </template>
            </template>
            <template v-if="trainingsWithoutGroup.length">
              <tr>
                <td><strong>Без группы</strong></td>
                <td class="date__width"></td>
                <td class="two-actions__width"></td>
              </tr>
              <template v-for="training in trainingsWithoutGroup">
              <tr :key="'training_'+training.id">
                <td style="padding-left: 50px">{{ training.name }}</td>
                <td>{{ new Date(training.created_at) | moment("DD.MM.YYYY HH:mm") }}</td>
                <td class="two-actions__width">
                  <div class="d-flex">
                    <v-tooltip top>
                      <template #[`activator`]="{ on, attrs }">
                        <v-btn @click="editTrainingForm(training.id)" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      Редактировать
                    </v-tooltip>
                    <v-tooltip top>
                      <template #[`activator`]="{ on, attrs }">
                        <v-btn @click="confirmTrainingRemove(training.id)" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                      Удалить
                    </v-tooltip>
                  </div>
                </td>
              </tr>
              </template>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <!-- <trainings-list  ref="list" @remove="confirmRemove" @edit="openEditForm"></trainings-list> -->
      <remove-dialog :open="openRemoveTrainingDialog" @confirm="removeTraining" @close="closeRemoveTrainingDialog">
        <template #title>Отправить тренинг в архив</template>
        <template #body>Вы действительно хотите отправить тренинг в архив?</template>            
      </remove-dialog>
       <remove-dialog :open="openRemoveGroupDialog" @confirm="removeGroup" @close="closeRemoveGroupDialog">
        <template #title>Отправить группу в архив</template>
        <template #body>Вы действительно хотите отправить группу тренингов в архив?</template>            
      </remove-dialog>
      <trainings-form :open="openTrainingForm" :groups="groups" :editId="editTrainingId" @close="closeTrainingForm"></trainings-form>
      <trainings-group-form :open="openGroupForm" :groups="groups"  :editId="editGroupId" @close="closeGroupForm"></trainings-group-form>
  </div>
</template>

<script>
 
/* import TrainingsList from './TrainingsList' */
import TrainingsForm from './TrainingsForm'
import TrainingsGroupForm from './TrainingsGroupForm'
import RemoveDialog from '../main/RemoveDialog'
export default {
  components: {
    /* TrainingsList, */
    TrainingsForm,
    TrainingsGroupForm,
    RemoveDialog
  },
  data: () => ({
    openTrainingForm: false,
    openGroupForm: false,
    openRemoveTrainingDialog: false,
    openRemoveGroupDialog: false,
    removeTrainingId: null,
    removeGroupId: null,
    editTrainingId: null,
    editGroupId: null,
    groups: [],
  }),
  created() {
    this.getGroups();
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    trainings() {
      return this.$store.state.trainings;
    },
    trainingsWithoutGroup() {
      let groupIds = this.groups.map(g => g.id);
      return this.trainings.filter(t => !groupIds.includes(t.group.id));
    }
  },
  methods: {
    getGroups() {
      this.loading = true
      let url = '/trainings-groups';
       this.$api.get(url)
        .then(resp => {
          this.loading = false;
          this.groups = resp.data._embedded.groups;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    editTrainingForm(id) {
      this.editTrainingId = id;
      this.openTrainingForm = true;
    },
    openCreateTrainingForm() {
      this.editTrainingId = null;
      this.openTrainingForm = true;
    },
    closeTrainingForm() {
      this.editTrainingId = null;
      this.openTrainingForm = false;
    },
    confirmTrainingRemove(id) {
      this.removeTrainingId = id;
      this.openRemoveTrainingDialog = true;
    },
    closeRemoveTrainingDialog() {
      this.removeTrainingId = null;
      this.openRemoveTrainingDialog = false;
    },
    removeTraining() {
      let id = this.removeTrainingId;
      let url = `/trainings/${id}`;
      this.openRemoveTrainingDialog = false;
       this.$api.delete(url)
        .then(() => {
          this.$store.dispatch('getTrainings')
          this.$store.dispatch('addMessage', 'Тренинг отправлен в архив')
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    editGroupForm(id) {
      this.editGroupId = id;
      this.openGroupForm = true;
    },
    openCreateGroupForm() {
      this.editGroupId = null;
      this.openGroupForm = true;
    },
    closeGroupForm() {
      this.editGroupId = null;
      this.openGroupForm = false;
    },
    confirmGroupRemove(id) {
      this.removeGroupId = id;
      this.openRemoveGroupDialog = true;
    },
    closeRemoveGroupDialog() {
      this.removeGroupId = null;
      this.openRemoveGroupDialog = false;
    },
    removeGroup() {
      let id = this.removeGroupId;
      let url = `/trainings-groups/${id}`;
      this.openRemoveGroupDialog = false;
       this.$api.delete(url)
        .then(() => {
          this.getGroups();
          this.$store.dispatch('getTrainings');
          this.$store.dispatch('addMessage', 'Группа тренингов отправлена в архив');
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>

<style>

</style>