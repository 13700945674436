var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap mb-6"},[_c('v-btn',{staticClass:"mt-3 mr-3",attrs:{"dark":"","color":"teal"},on:{"click":_vm.openCreateTrainingForm}},[_vm._v("Добавить тренинг")]),_c('v-btn',{staticClass:"mt-3",attrs:{"dark":"","color":"teal"},on:{"click":_vm.openCreateGroupForm}},[_vm._v("Добавить группу тренингов")])],1),_c('v-simple-table',[[_c('thead',{staticClass:"blue-grey lighten-5"},[_c('tr',{staticClass:"text-caption grey--text "},[_c('th',{staticClass:"text-left font-weight-regular"},[_vm._v(" Группа ")]),_c('th',{staticClass:"text-left font-weight-regular"},[_vm._v(" Дата создания ")]),_c('th')])]),_c('tbody',[_vm._l((_vm.groups),function(item){return [_c('tr',{key:'group_'+item.id},[_c('td',[_c('strong',[_vm._v(_vm._s(item.name))])]),_c('td',{staticClass:"date__width"},[_vm._v(_vm._s(_vm._f("moment")(new Date(item.created_at),"DD.MM.YYYY HH:mm")))]),_c('td',{staticClass:"two-actions__width"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editGroupForm(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Редактировать ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.confirmGroupRemove(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_vm._v(" Удалить ")])],1)])]),_vm._l((_vm.trainings.filter(function (t) { return t.group.id == item.id; })),function(training){return [_c('tr',{key:'training_'+training.id},[_c('td',{staticStyle:{"padding-left":"50px"}},[_vm._v(_vm._s(training.name))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(new Date(training.created_at),"DD.MM.YYYY HH:mm")))]),_c('td',{staticClass:"two-actions__width"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editTrainingForm(training.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Редактировать ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.confirmTrainingRemove(training.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_vm._v(" Удалить ")])],1)])])]})]}),(_vm.trainingsWithoutGroup.length)?[_c('tr',[_c('td',[_c('strong',[_vm._v("Без группы")])]),_c('td',{staticClass:"date__width"}),_c('td',{staticClass:"two-actions__width"})]),_vm._l((_vm.trainingsWithoutGroup),function(training){return [_c('tr',{key:'training_'+training.id},[_c('td',{staticStyle:{"padding-left":"50px"}},[_vm._v(_vm._s(training.name))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(new Date(training.created_at),"DD.MM.YYYY HH:mm")))]),_c('td',{staticClass:"two-actions__width"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editTrainingForm(training.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Редактировать ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.confirmTrainingRemove(training.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_vm._v(" Удалить ")])],1)])])]})]:_vm._e()],2)]],2),_c('remove-dialog',{attrs:{"open":_vm.openRemoveTrainingDialog},on:{"confirm":_vm.removeTraining,"close":_vm.closeRemoveTrainingDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Отправить тренинг в архив")]},proxy:true},{key:"body",fn:function(){return [_vm._v("Вы действительно хотите отправить тренинг в архив?")]},proxy:true}])}),_c('remove-dialog',{attrs:{"open":_vm.openRemoveGroupDialog},on:{"confirm":_vm.removeGroup,"close":_vm.closeRemoveGroupDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Отправить группу в архив")]},proxy:true},{key:"body",fn:function(){return [_vm._v("Вы действительно хотите отправить группу тренингов в архив?")]},proxy:true}])}),_c('trainings-form',{attrs:{"open":_vm.openTrainingForm,"groups":_vm.groups,"editId":_vm.editTrainingId},on:{"close":_vm.closeTrainingForm}}),_c('trainings-group-form',{attrs:{"open":_vm.openGroupForm,"groups":_vm.groups,"editId":_vm.editGroupId},on:{"close":_vm.closeGroupForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }