<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-card>
      <v-card-title class="mb-7">
        <span class="headline">{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="name" label="Название группы" :rules="[rules.requiredName]" color="teal"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn dark color="blue-grey lighten-2" @click="close">
          Отмена
        </v-btn>
        <v-btn dark color="teal" @click="save">
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
 
export default {
  props: {
    editId: null,
    groups: {
      type: Array,
    },
    open: {
      default: false,
      type: Boolean
    },
  },
  data: () => ({
    name: '',    
    valid: false,
    rules: {
      requiredName: value => !!value || 'Введите название группы.',
    },
  }),
  watch: {
    editId() {
      let group = this.groups.find(g => g.id == this.editId);
      if (group) {
        this.name = group.name;
      } 
    }
  },
  
  computed: {
    btnText() {
      return this.editId ? 'Обновить' : 'Добавить'
    },
    dialogTitle() {
      return this.editId ? 'Обновить группу' : 'Добавить группу'
    }
  },
  methods: {
    
    clear() {
      this.name = '';
    },
    close() {
      this.clear();
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editId) {
          this.patch()
        } else {
          this.store()
        }
      }
    },
    store() {
      let url = '/trainings-groups'
       this.$api.post(url,{name: this.name})
        .then(() => {
          this.$parent.getGroups();
          this.$store.dispatch('addMessage', 'Группа сохранена')
          this.close();
        })
        .catch(error => {     
          console.log(error);      
          this.$store.dispatch('addRequestError', error)
        })
    },
    patch() {
      let url = `/trainings-groups/${this.editId}`
       this.$api.patch(url,{name: this.name})
        .then(() => {
          this.$parent.getGroups();
          this.$store.dispatch('addMessage', 'Изменения сохранены')
          this.close();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>

<style>

</style>