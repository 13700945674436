<template>
  <div class="training">
    <page-title :title="'Тренинги'"></page-title>
    <v-card class="pa-3 rounded-0 flat custom__card">
      <trainings-tab></trainings-tab>   
    </v-card>
  </div>
</template>
<script>

import PageTitle from '../../components/main/PageTitle'
import TrainingsTab from '../../components/trainings/TrainingsTab'
export default {
  components: {
    TrainingsTab,
    PageTitle,
  },
}
</script>

